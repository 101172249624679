import * as React from 'react';

interface TabPanelProps<T> {
  value: T;
  id: T;
  children: React.ReactNode;
}

export default function TabPanel<T> (props: TabPanelProps<T>) {
  const { value, id, children } = props;

  return (
    <>
      { value === id && children }
    </>
  );
}