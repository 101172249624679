import _,{ filter, get, has, isEmpty, omit, pick, isEqual, isUndefined, lte, isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { call, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { handleActions, combineActions } from 'redux-actions';
import { sanitize } from '@ivymobi/orbit-domain';
import { AnyAction } from 'redux';

import {
  createPromiseAction,
  dispatch,
  rejectPromiseAction, resolvePromiseAction,
} from '../util/redux-saga-promise';
import { ContextUser, Tenant, TenantActionMeta, Document } from '../types';


export const useDocuments = ({user: {user}, tenant}: TenantActionMeta) => useSelector(
  (state) => {
    return get(state, [user.id, 'tenants', tenant.id, 'documents']);
  },
  // equalMethod
);
// const equalMethod = (newVal, oldVal) => {
//   console.log({newVal});
//   console.log({oldVal});
//   console.log(newVal === oldVal);
//   return newVal === oldVal;
// };
export const useDocument = ({user: {user}, tenant, documentId = null}: TenantActionMeta & {documentId?: string}) => useSelector(
  (state) => {
    if (has(state, [user.id, 'tenants', tenant.id, 'documents'])) {
      const docs = state[user.id].tenants[tenant.id].documents;
      if (isEmpty(documentId)) {
        // 根目录/文档
        const rootDocs = [];
        for (const key in docs) {
          // isEmpty(docs[key].parents) ? rootDocs.push(docs[key]) : '';
          isNull(docs[key].parents)&&(docs[key].type!='catalog'&&docs[key].type!='product') ? rootDocs.push(docs[key]) : '';
        }
        // const rootDocs = filter(docs, doc => isEmpty(doc.parents));
        if (rootDocs.length > 0) {
          return rootDocs[0];
        } else {
          return undefined;
        }
      } else {
        return docs[documentId];
      }
    }
    return undefined;
  },
  isEqual // doesn't work!
);
//添加网页链接
export const addNetPageAction = createPromiseAction(
  'documents/NETPAGE_ADD',
  (data) => pick(data.payload, ['documentId', 'name', 'urlstr']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchAddNetPage(api) {
  yield takeEvery<AnyAction>(addNetPageAction, function* (action) {
    try {
      const {
        meta: {user: {token, user}, tenant},
        payload: { documentId, name, urlstr }
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}/weblink`,
        token,
        body: {urlstr: urlstr, name: name }
      });
      const doc = yield sanitize.weblink(res);
      yield call(resolvePromiseAction, action, doc, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 获取版本信息
export const getCheckInfoAction = createPromiseAction(
  'document/CheckInfo',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, [ 'tenant'])
);

export function* watchCheckInfo(api) {
  yield takeEvery(getCheckInfoAction, function* (action) {
    try {
      const {meta: { tenant}} = action;
      const url = `tenants/${tenant.id}/checkOpsinfo`;
      const res = yield call(api.getJSON, {
        url,
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const addDocumentAction = createPromiseAction(
  'documents/DOCUMENT_ADD',
  (data) => pick(data.payload, ['documentId', 'asset']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

// web
function* watchAddDocument(api) {
  yield takeEvery<AnyAction>(addDocumentAction, function* (action) {
    try {
      const {
        meta: {user: {token, user}, tenant},
        payload: { documentId, asset }
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}/file`,
        token,
        body: {assetId: asset.id, name: asset.name }
      });

      const doc = yield sanitize.document(res);

      yield call(resolvePromiseAction, action, doc, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 小程序
export const addDocumentActionSmall = createPromiseAction(
  'documents/DOCUMENT_ADD_SMALL',
  (data) => pick(data.payload, ['documentId', 'asset']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchAddDocumentSmall(api) {
  yield takeEvery<AnyAction>(addDocumentActionSmall, function* (action) {
    try {
      const {
        meta: {user: {token, user}, tenant},
        payload: { documentId, asset }
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}/file`,
        token,
        body: {
          assetId: asset.id,
          name: asset.name,
          ispersion: '1'
        }
      });
      const doc = yield sanitize.document(res);
      yield call(resolvePromiseAction, action, doc, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

//批量增加文件
export const addDocumentsAction = createPromiseAction(
  'documents/DOCUMENTS_ADD',
  (data) => pick(data.payload, ['documentId', 'assetIds', 'assetNames', 'acl','isPerson']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchAddDocuments(api) {
  yield takeEvery<AnyAction>(addDocumentsAction, function* (action) {
    try {
      const {
        meta: {user: {token, user}, tenant},
        payload: { documentId, assetIds,  assetNames, acl,isPerson}
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}/files`,
        token,
        body: {assetIds: assetIds, assetNames: assetNames, acl: acl, ispersion:isPerson?'1':'0'}
      });
      const docs = [];
      for (let i=0;i<res.length;i++) {
        const doc = yield sanitize.document(res[i]);
        docs.push(doc);
      }
      
      yield call(resolvePromiseAction, action, docs, { user, tenant, isPerson});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const addFolderAction = createPromiseAction(
  'documents/FOLDER_ADD',
  (data) => pick(data.payload, ['documentId', 'doc']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchAddFolder(api) {
  yield takeEvery(addFolderAction, function* (action) {
    try {
      const {
        payload: { documentId, doc },
        meta: {user: {token, user}, tenant}
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}`,
        token,
        body: doc
      });
      const folder = yield sanitize.document(res);
      yield call(resolvePromiseAction, action, folder, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
      throw error;
    }
  });
}

export const addWeblinkAction = createPromiseAction(
  'documents/WEBLINK_ADD',
  (data) => pick(data.payload, ['documentId', 'doc']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchAddWeblink(api) {
  yield takeEvery(addWeblinkAction, function* (action) {
    try {
      const {
        payload: { documentId, doc },
        meta: {user: {token, user}, tenant}
      } = action;
      const res = yield call(api.postJSON, {
        url: `tenants/${tenant.id}/documents/${documentId}/weblink`,
        token,
        body: doc
      });
      const docs = yield sanitize.weblink(res);
      docs.name = doc.name;
      yield call(resolvePromiseAction, action, docs, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
      throw error;
    }
  });
}


/**
 * 获取根文档，或者指定文档的子文档（如果有的话）。
 * @param payload：文档ID，可选。未设置时，表示获取根文档。
 */
export const getDocumentAction = createPromiseAction(
  'document/GET',
  (data: {id?: string, nameFilter?:string}) => pick(data, ['id', 'nameFilter']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchGetDocument(api) {
  yield takeEvery(getDocumentAction, function* (action) {
    try {
      const {payload: {id, nameFilter}, meta: {user: {token, user}, tenant}} = action;
      const url = isEmpty(id) ?
        (nameFilter?`tenants/${tenant.id}/documents?nameFilter=${nameFilter}`
          :`tenants/${tenant.id}/documents`)
        :(nameFilter?`tenants/${tenant.id}/documents/${id}?nameFilter=${nameFilter}`:
          `tenants/${tenant.id}/documents/${id}`) ;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      let docs = null;
      if (res.type==='catalog'||res.type==='product') {
        docs= [res];
      } else {
        docs = yield sanitize.documentList(res);
      }
      const folderImg = _.get(tenant, ['info', 'companyCoverCurrentUrl'], null);
      docs.forEach((item) => {
        if (item.type === 'folder' && _.isNil(item.thumbnail)) {
          item.thumbnail = folderImg;
        }
      });
      // console.log('docsdocsdocs', docs);
      yield call(resolvePromiseAction, action, docs, {user, tenant});
    } catch (error) {
      console.log('报错了', error)
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getDocumentTreeAction = createPromiseAction(
  'document/GETTREE',
  (data: {id?: string, nameFilter?:string}) => pick(data, ['id', 'nameFilter']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchGetDocumentTree(api) {
  yield takeEvery(getDocumentTreeAction, function* (action) {
    try {
      const {payload: {id, nameFilter}, meta: {user: {token, user}, tenant}} = action;
      // const url = isEmpty(id) ?
      //   (nameFilter?`tenants/${tenant.id}/documents?nameFilter=${nameFilter}`
      //     :`tenants/${tenant.id}/documents`)
      //   :(nameFilter?`tenants/${tenant.id}/documents/${id}?nameFilter=${nameFilter}`:
      //     `tenants/${tenant.id}/documents/${id}`);
          
      const url = `tenants/${tenant.id}/getDocumentsTree`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      yield call(resolvePromiseAction, action, res, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const getUpdateHisAction = createPromiseAction(
  'document/GET_UPDATE_HISTORY',
  (data) => pick(data),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchGetUpdateHis(api) {
  yield takeEvery(getUpdateHisAction, function* (action) {
    try {
      const { meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/uploadHis`;
      const res = yield call(api.getJSON, {
        url,
        token
      });
      // debugger
      // console.log('docsdocsdocsdocs', docs);
      yield call(resolvePromiseAction, action, res.data, {user, tenant});
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 上传新版本
export const updateFileAction = createPromiseAction(
  'document/UPDATEFILE',
  (data: {parentsId?: string, originfileId?: string, assetId?: string, assetName?: string}) => 
    pick(data, ['parentsId', 'originfileId', 'assetId', 'assetName']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchUpdateFile(api) {
  yield takeEvery(updateFileAction, function* (action) {
    try {
      const {payload: {parentsId, originfileId, assetId, assetName}, meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${originfileId}/parentId/${parentsId}`;
      const res = yield call(api.postJSON, {
        url,
        token,
        body: {
          assetId: assetId,
          assetName: assetName,
        }
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

/**
 * @param payload：根据文档 id 列表获取文档列表
 */
export const getDocumentListByIds = createPromiseAction(
  'document/listByIds',
  (data) => pick(data, ['ids', 'user', 'tenant']),
  (data) => pick(data, ['user', 'tenant'])
);

function* watchGetDocumentListByIds() {
  yield takeLatest<AnyAction>(getDocumentListByIds, function* (action) {
    try {
      const { ids, user, tenant } = action.payload;
      for (const id of ids) {
        // console.log('执行了get', id);
        try {
          yield dispatch(getDocumentAction, { id, user, tenant });
        } catch (err) {
          console.error(err);
        }
        
      }
      yield resolvePromiseAction(action);
    } catch (e) {
      yield rejectPromiseAction(action, e);
    }
  });
}

export const isExistNoShareFiles = createPromiseAction(
  'document/IS_EXISTNOSHAREFILES',
  (data: {id?: string}) => pick(data, ['id']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchIsExistNoShareFiles(api) {
  yield takeEvery(isExistNoShareFiles, function* (action) {
    try {
      const {payload: {id}, meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${id}/isExistNoShareFiles`;
      const res = yield call(api.getJSON, {
        url,
        token
      });

      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 删除 document
export const deleteDocumentAction = createPromiseAction(
  'document/DELETE',
  (data) => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchDeleteDocument(api) {
  yield takeEvery(deleteDocumentAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${action.payload.parentid}/${action.payload.id}`;
      // const url = `tenants/${tenant.id}/documents/${action.payload.parentid}`;
      yield call(api.deleteJSON, {
        url,
        token,
        // body: {documents: action.payload.id}
      });

      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 删除 documents
export const deleteDocumentAction2 = createPromiseAction(
  'document/DELETEs',
  (data) => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchDeleteDocument2(api) {
  yield takeEvery(deleteDocumentAction2, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      // const url = `tenants/${tenant.id}/documents/${action.payload.parentid}/${action.payload.id}`;
      const url = `tenants/${tenant.id}/documents/${action.payload.parentid}`;
      yield call(api.deleteJSON, {
        url,
        token,
        body: {documents: action.payload.id}
      });

      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}



//复制文档
export const copyDocumnetAction =  createPromiseAction(
  'document/COPY',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchCopyDocument(api) {
  yield takeEvery(copyDocumnetAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${action.payload.targetId}/copyto`;
      yield call(api.postJSON, {
        url,
        token,
        body: {'ids':action.payload.ids},
      });
      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
//移动文档
export const moveDocumnetAction =  createPromiseAction(
  'document/MOVE',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchMoveDocument(api) {
  yield takeEvery(moveDocumnetAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${action.payload.parentId}/target/${action.payload.targetId}`;
      yield call(api.postJSON, {
        url,
        token,
        body: {'ids':action.payload.ids},
      });
      console.log("****************",action)
      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
export const updateDocumentFilesAction = createPromiseAction(
  'document/UPDATE_FILES',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);
function* watchUpdateDocumentFiles(api) {
  yield takeEvery(updateDocumentFilesAction, function* (action) {
    try {  
      const {meta: {user: {token, user}, tenant}} = action;

      const acl = action.payload.acl;
      const res = yield call(api.putJSON, {
        url: `tenants/${action.meta.tenant.id}/updateDocumentFiles`,
        token: action.meta.user.token,
        body: {astId:action.payload.arr, acl:action.payload.acl.acl},
      });
      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 更新 document
export const updateDocumentAction = createPromiseAction(
  'document/UPDATE',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchUpdateDocument(api) {
  yield takeEvery(updateDocumentAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/documents/${action.payload.id}`;
      yield call(api.putJSON, {
        url,
        token,
        body: action.payload,
      });
      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

// 更新 document
export const sortDocumentAction = createPromiseAction(
  'document/SORT_UPDATE',
  data => data.payload,
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchSortDocument(api) {
  yield takeEvery(sortDocumentAction, function* (action) {
    try {
      const {meta: {user: {token, user}, tenant}} = action;
      const url = `tenants/${tenant.id}/moveDocuments/${action.payload.id}`;
      yield call(api.putJSON, {
        url,
        token,
        body: action.payload,
      });
      yield call(resolvePromiseAction, action, action.payload, { user, tenant });
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}
// 获取版本信息
// export const getCheckInfoAction = createPromiseAction(
//   'document/CheckInfo',
//   data => data.payload,
//   (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
// );

// function* watchCheckInfo(api) {
//   yield takeEvery(getCheckInfoAction, function* (action) {
//     try {
//       const {meta: {user: {token, user}, tenant}} = action;

//       const url = `tenants/${tenant.id}/checkOpsinfo`;
//       const res = yield call(api.getJSON, {
//         url,
//         token
//       });
//       yield call(resolvePromiseAction, action, res);
//     } catch (error) {
//       yield call(rejectPromiseAction, action, error);
//     }
//   });
// }

// reducer reducer
export const reducer = handleActions({
  [combineActions(addDocumentAction.resolved, addFolderAction.resolved, addWeblinkAction.resolved)]: (state, action) => {
    const doc = action.payload;
    const parentDoc = state[doc.parents[0]] ?? {};
    return {
      ...state,
      [parentDoc.id]: {
        ...parentDoc,
        documents: [...(parentDoc.documents ?? []), doc.id]
      },
      [doc.id]: doc
    };
  },
  [combineActions(addDocumentsAction.resolved)]: (state, action) => {
    if (action.meta.isPerson)
      return state;
    // 24.1.3  修改上传后的展示顺序，最新上传的在最前边
    // console.log('~~~~~~~~~~reducer statestatestatestatestatestatestate', state);
    // console.log('~~~~~~~~~~reducer actionactionactionactionactionaction', action);
    const docs = action.payload;  //  上传的文件列表Arrays
    const parentDocs = {};  //
    const docList = {}; 
    for (let i=0;i<docs.length;i++) {
      const doc = docs[i];
      const parentDoc = state[docs[i].parents[0]] ?? {};  //  父级ID

      if (isUndefined(parentDocs[parentDoc.id])) {
        parentDocs[parentDoc.id] =  {...parentDoc, documents: [doc.id, ...(parentDoc.documents ?? [])]};
      }
      else {
        parentDocs[parentDoc.id] =  {...parentDocs[parentDoc.id], documents: [ doc.id, ...(parentDocs[parentDoc.id].documents ?? [])]};
      }

      docList[doc.id]=doc;
    }
    return {
      ...state,
      ...parentDocs,
      ...docList
    };
  },
  [combineActions(addNetPageAction.resolved)]: (state, action) => {
    const doc = action.payload;
    const parentDoc = state[doc.parents[0]] ?? {};
    return {
      ...state,
      [parentDoc.id]: {
        ...parentDoc,
        documents: [...(parentDoc.documents ?? []), doc.id]
      },
      [doc.id]: doc
    };
  },
  [combineActions(moveDocumnetAction.resolved)]: (state, action) => {
   
    // const sortdocs = action.payload.documents;
    // const parentid = action.payload.id;
    let parentDoc = state[action.payload.parentId];
    
    // const parentDoc = state[doc.parents[0]] ?? {};
    return {
      ...state,
      [action.payload.parentId]: {
        ...state[action.payload.parentId],
        documents: _.difference(parentDoc?.documents,action.payload.ids)
      },
      // [doc.id]: doc
    };
  },
  [combineActions(sortDocumentAction.resolved)]: (state, action) => {
    const sortdocs = action.payload.documents;
    const parentid = action.payload.id;
  
    // const parentDoc = state[doc.parents[0]] ?? {};
    return {
      ...state,
      [parentid]: {
        ...state[parentid],
        documents: sortdocs
      },
      // [doc.id]: doc
    };
  },
  [combineActions(addWeblinkAction.resolved)]: (state, action) => {
    const doc = action.payload;
    const parentDoc = state[doc.parents[0]] ?? {};
    return {
      ...state,
      // [parentDoc.id]: {
      //   ...parentDoc,
      //   documents: [...(parentDoc.documents ?? []), doc.id]
      // },
      // [doc.id]: doc
    };
  },
  [deleteDocumentAction.resolved.toString()]: (state, action) => {
    return omit(state, action.payload.id);
  },
  [updateDocumentAction.resolved.toString()]: (state, action) => {

    const id = action.payload.id;
    const oldDocument = state[id];
    return {... state, [id]: { ...oldDocument, ...action.payload }};
  },
  [combineActions(updateDocumentFilesAction.resolved)]: (state, action) => {
    const ids = action.payload.arr;
    let acls = {};
    const keys = Object.keys(state);
    for (let m=0;m<keys.length;m++) {
      const key = keys[m];
      for (let i=0;i<ids.length;i++) {
        const id =  ids[i];
        const doc_id = state[key].id;
        if (state[key].assetId===id) {
          acls = {...acls, [doc_id]:{...state[key], ...action.payload.acl}};
        }

      }
    }
    return {...state, ...acls};
  },
  [combineActions(getDocumentAction.resolved.toString())]: (state, action) => {
    const docs = [].concat(action.payload).reduce((prev, doc) => {
      if(doc.type==='catalog'||doc.type==='product'){
        doc['name' ] = doc?.name['zh']
      }
      prev[doc.type==='catalog'?doc.cgId:(doc.type==='product'?doc.details.supplierAltPid:doc.id)] = doc;
      return prev;
    }, {});
    // console.log('combine执行了combine执行了combine执行了combine执行了', state, docs, _.merge(state, docs));
    return {...state, ...docs};
    // return Object.assign({}, state, docs);
    
    // const keys = Object.keys(docs);
    // const filterstate = _.omit(state, keys);
  }
}, {});

export interface UserDocumentEvent {
  type: 'view'|'share'|'download'|'fav';
  shareType?: string;
  user: ContextUser;
  tenant: Tenant;
  document: Document;
  detail?: {[key: string]: unknown}
}

export const documentEventAction = createPromiseAction(
  'documents/EVENT',
  (data: UserDocumentEvent) => {
    const {user: {user, token}, document, type, shareType, tenant, eventId} = data;
    return {user, documentId: document.id, type, shareType, token, tenant, eventId};
  },
  data => pick(data, ['user', 'document', 'type', 'tenant'])
);

export function* watchDocumentEvent(api) {
  yield takeLeading(documentEventAction, function* (action) {
    const {documentId, token, tenant, type, shareType, eventId} = action.payload;
    let bodyData = {};
    if (type === 'share') {
      bodyData = {type, shareType};
    } else if (type === 'view') {
      bodyData = {type, eventId};
    } else {
      bodyData = {type};
    }

    try {
      const res = yield call(api.postJSON, {
        token: token,
        url: `stats/${tenant.id}/documents/${documentId}/events`,
        body: bodyData
      });
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}


// 浏览时长的--埋点--方法  ----mode-----post事件模板
export const setBrowseTimeAction = createPromiseAction(
  'documents/BROWSE_TIME',
  // (data) => pick(data.payload, ['eventID', 'time']),
  (data: {eventID?: string, time?:string}) => pick(data, ['eventID', 'time']),
  (data: TenantActionMeta) => pick(data, ['user', 'tenant'])
);

function* watchBrowseTime(api) {
  yield takeEvery<AnyAction>(setBrowseTimeAction, function* (action) {
    try {
      const {
        payload: { eventID, time },
        meta: {
          user: {token}, 
          tenant
        }
      } = action;
      const res = yield call(api.postJSON, {
        url: `stats/documents/browsing/time/${eventID}`,
        token,
        body: {
          time: time
        }
      });
      // const doc = yield sanitize.document(res);
      yield call(resolvePromiseAction, action, res);
    } catch (error) {
      yield call(rejectPromiseAction, action, error);
    }
  });
}

export const watchers = [
  watchAddDocument, watchAddDocumentSmall, watchAddDocuments, watchAddFolder, watchGetDocument, watchAddNetPage, watchAddWeblink,
  watchDeleteDocument, watchDeleteDocument2, watchSortDocument, watchUpdateDocument, watchUpdateDocumentFiles, watchMoveDocument, watchCopyDocument,
  watchGetDocumentListByIds, watchUpdateFile, watchGetUpdateHis, watchDocumentEvent, watchIsExistNoShareFiles, watchGetDocumentTree,
  watchBrowseTime,watchCheckInfo
];
