import React, { useState, useRef, useEffect, useCallback } from 'react';
import isEqual from 'lodash/isEqual';

export function useSafeSetState<T>(
  initialState?: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(initialState);

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = useCallback(
    args => {
      if (mountedRef.current) {
        return setState(args);
      }
    },
    [mountedRef, setState]
  );

  return [state, safeSetState];
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDeepCompareEffect(callback, inputs) {
  const cleanupRef = useRef();
  useEffect(() => {
    if (!isEqual(previousInputs, inputs)) {
      cleanupRef.current = callback();
    }
    return cleanupRef.current;
  });
  const previousInputs = usePrevious(inputs);
}

export function useToggle(initStauts = false): [boolean, () => void] {
  const [open, setOpen] = useState(initStauts);
  const toggle = useCallback(() => setOpen(pre => !pre), [setOpen]);
  return [open, toggle];
}
