import React from 'react';
import { Block, BlockType, RenderFunc } from './type';

const Element: RenderFunc = ({ children, block, onClick }) => {
  switch (block.type) {
  case 'block-quote':
    return (
      <blockquote
        style={{
          fontSize: '1.1em',
          fontStyle:'italic',
          color: '#555555',
          padding:'8px',
          borderLeft: '8px solid #78C0A8',
          lineHeight:1.6,
          position: 'relative',
          background:'#EDEDED',
        }}
      >
        {children}
      </blockquote>
    );
  case 'bulleted-list':
    return (
      <ul>
        {children}
      </ul>
    );
  case 'heading-one':
    return (
      <h1>
        {children}
      </h1>
    );
  case 'heading-two':
    return (
      <h2>
        {children}
      </h2>
    );
  case 'list-item':
    return (
      <li>
        {children}
      </li>
    );
  case 'numbered-list':
    return (
      <ol>
        {children}
      </ol>
    );
  case 'link':
    return (
      <a 
        target="_blank" rel="noopener noreferrer" 
        href={block?.href}
      >
        {children}
      </a>
    );
  case 'video':
    return (
      <video controls>
        <source src={block?.href} />
          Your browser does not support the video tag.
      </video>
    );
  case 'image':
    return (
      <img onClick={() => {onClick(block);}}
        src={block?.src} 
        style={{
          marginLeft: 'auto', marginRight: 'auto',
          maxWidth: '100%', objectFit: 'contain'
        }}
      />
    );
  case 'horizontal-rule':
    return <hr />;
  case 'table':
    return (
      <table 
        style={{
          border: '1px solid #ccc',
          borderCollapse: 'collapse',
          textAlign: 'center'
        }}
      >
        <tbody>{children}</tbody>
      </table>
    );
  case 'table-row':
    return <tr>{children}</tr>;
  case 'table-cell':
    return <td
      style={{
        border: '1px solid #ccc',
        padding: '12px'
      }}
    >{children}</td>;
  default:
    return (
      <p>
        {children}
      </p>
    );
  }
};

const Leaf: RenderFunc = ({ children, block }) => {
  if (block.bold) {
    children = <strong>{children}</strong>;
  }

  if (block.code) {
    children = <code>{children}</code>;
  }

  if (block.italic) {
    children = <em>{children}</em>;
  }

  if (block.underline) {
    children = <u>{children}</u>;
  }
  
  if (block.highlight) {
    children = <mark>{children}</mark>;
  }

  return <span >{children}</span>;
};



function inferBlockType (block: Block): BlockType {
  if (!Object.prototype.hasOwnProperty.call(block, 'type')) {
    return 'leaf';
  }

  if (block.type === 'document') return 'document';

  return 'element';
}

interface TextRendererProps {
  block: Block;
}
export function TextRenderer (props: TextRendererProps): JSX.Element {
  const { block } = props;

  return (
    <>{block.text}</>
  );
}

interface RichTextRendererProps {
  renderLeaf: RenderFunc;
  renderElement: RenderFunc;
  block: Block;
  onClick;
}
export function RichTextRenderer (props: RichTextRendererProps): JSX.Element {
  const {
    renderLeaf, renderElement, block, onClick
  } = props;

  const blockType = inferBlockType(block);

  if (blockType === 'document') {

    return (
      <>
        {
          block.children.map((b, index) => {
            return (
              <RichTextRenderer
                key={index.toString()}
                renderLeaf={renderLeaf} 
                renderElement={renderElement} 
                block={b} 
                onClick={onClick}
              />
            );
          })
        }
      </>
    );
  }

  if (blockType === 'element') {
    const children = (block.children || []).map((b, index) => {

      return (
        <RichTextRenderer
          key={index.toString()}
          renderLeaf={renderLeaf} 
          renderElement={renderElement} 
          block={b} 
          onClick={onClick}
        />
      );
    });

    return renderElement({block, children, onClick});
  }

  if (blockType === 'leaf') {
    return renderLeaf({block, children: <TextRenderer block={block} />, });
  }
}

interface RichTextProps {
  data: Block[];
  onClick?;
}
export default function RichText (props: RichTextProps): JSX.Element {
  const {
    data = [],
    onClick,
  } = props;

  return (
    <RichTextRenderer 
      renderElement={Element}
      renderLeaf={Leaf}
      block={{type: 'document', children: data }}
      onClick={onClick}
    />
  );
}