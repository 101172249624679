
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { getFileType } from './fileType';
    // 智能样本通用埋点事件
    export const  SampleSendEvent = (dispatch,userShareEvent,type, document, detail = null,eventID,user,tenant,memberId=null) => {
        let requestDic = {
          isCampaign:false, 
          user:{user}, 
          share:{
            id:'11111111-1111-1111-1111-111111111111', 
            tenantId:tenant.id ,
            tenant
          }, 
          document, 
          type, 
          detail 
        }
        let filetype = getFileType(document?.asset?.name??' ')
        if(type=='view'&&eventID&& filetype=='pdf')
          requestDic['eventId'] = eventID;
        if(memberId){
            requestDic['share']['memberId'] = memberId;
        }
        return dispatch(userShareEvent(requestDic));
      };