import { useTheme } from '@material-ui/core';
import React from 'react';

export default function Avatar({
  name, size = 80, radius = 0, color = 'white',
  style = {},
  ...props
}) {
  const theme = useTheme();
  const bgColor = props.bgColor ?? theme.palette.primary.light;
  name = (name?name:'');

  const avatarName = name.split(' ')
  .map(i => i.charAt(0).toUpperCase())
  .join('');

  return (
    <div style={{
      height: size, width: size, borderRadius: radius,
      backgroundColor: bgColor, color,
      fontSize: size / 2,
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      ...style
    }}>
      <strong> {avatarName} </strong>
    </div>
  );
}